var ajaxMaker = {
    request: function (type, url, qParams, cbs) {
        switch (type) {
            case 'get':
                $.ajax({
                    async: true,
                    type: "GET",
                    url: url + this.getqParams(qParams),
                    success: function (response, status, xhr) {
                        cbs['success']['cb'].apply(cbs['success']['context'], arguments);
                    },
                    error: function (xhr, status, error) {
                        cbs['error']['cb'].apply(cbs['error']['context'], arguments);
                    }
                });
                break;

            case 'post':
                break;
        }
    },

    getqParams: function (qParams) {
        if (typeof qParams !== 'undefined')
            return '?' + $.param(qParams);
    }

};

var tickerNews = {
    init: function (jqItem) {
        this.queue = [];
        this.active = false;
        this.curIndex = -1;
        this.targetItem = jqItem
        this.running = false;
    },
    push: function (item) {
        this.queue.push(item);
    },
    reset: function () {
        this.queue = [];
        this.curIndex = -1;
    },
    stop: function () {
        this.active = false;
        this.targetItem.addClass('hidden');
        this.running = false;
        if (typeof this.tickerInterval !== 'undefined') {
            clearInterval(this.tickerInterval)
        }
    },
    start: function () {
        this.active = true;
        if (this.running) return;
        if (this.queue.length > 0) {
            this.targetItem.removeClass('hidden');
            this.observe();
            this.running = true;
        }
    },
    observe: function () {
        var tickerParent = this;
        if (!this.active) return;
        if (tickerParent.queue.length)
            tickerParent.curIndex++;
        tickerParent.show(function () {
            tickerParent.hide(function () {
                tickerParent.observe();
            });
        });
    },
    show: function (callback) {
        if (this.curIndex < 0) { return; }
        if (this.curIndex == this.queue.length) { this.curIndex = 0; }
        var curItem = this.queue[this.curIndex];
        this.targetItem.find('.ticker-text').text(curItem['title']);
        this.targetItem.find('.correct-timestamp').text(curItem['timestamp']);
        if ((typeof curItem['link'] !== 'undefined') && (curItem['link'] !== ''))
            this.targetItem.find('.ticker-news-link').attr('href', curItem['link']).attr('tagret', '_blank');
        else
            this.targetItem.find('.ticker-news-link').attr('href', '#').removeAttr('tagret');
        this.targetItem.find('.ticker-news-link').parent().removeClass('tk-flipOutX').addClass('tk-flipInX').addClass('tk-animate');
        setTimeout(callback, 3000);
    },
    hide: function (callback) {
        if (this.active) {
            this.targetItem.find('.ticker-news-link').parent().removeClass('tk-animate').removeClass('tk-flipInX').addClass('tk-flipOutX').addClass('tk-animate');
        }
        setTimeout(callback, 500);
    }
};

var tickerModule = {
    init: function () {
        this.prevCompleted = true;
        this.tInterval = null;
        this.tVersion = 'v1';
        this.ajaxMaker = Object.create(ajaxMaker);
        // this.tickerNews = Object.create(tickerNews);
        // this.tickerNews.init($('.ticker-news'));
        this.position();
        this.controller();
        this.expandibleChart();
    },

    controller: function () {
        if (!this.prevCompleted) return;
        this.prevCompleted = false;
        var factor = 1000 * 60 * 0.5;
        if ($('.ticker-module').length < 1) return;
        this.ajaxMaker.request('get', $('.ticker-module').attr('data-url'), { t: (Math.round(new Date().getTime() / factor) * factor) }, { success: { cb: this.successCb, context: this }, error: { cb: this.errorCb, context: this } })
    },

    successCb: function (response, status, xhr) {
        this.prevCompleted = true;
        if (status != 'success') return;
        if (typeof response == 'string') { response = JSON.parse(response); }
        if ((!response.data.active) || (response.data.version !== '1.0.0')) {
            $('.ticker-module').remove();
            $('style[data-styling="ticker"]').remove();
            // this.tickerNews.stop();
            clearInterval(this.tInterval);
            return;
        }
        this.renderChart(response);
        // this.renderButton(response);
        // this.renderNews(response);
    },

    errorCb: function (xhr, status, error) {
        console.log('Oops some error happened', status, error);
        if(+xhr.status >= 400 && +xhr.status < 500){
            $('.ticker-module').remove();
            $('style[data-styling="ticker"]').remove();
            clearInterval(this.tInterval);
            return;
        }else{
            $('.ticker-module').addClass('hidden');
            return;
        }
    },

    /* renderButton: function (response) {
        var button = $('.ticker-floating-bttn');
        var data = response.data.buttons.data;
        if (button.length < 1) return;
        data.forEach((buttonData) => {
            let buttonItem = buttonData.button;
            if (buttonItem.active) {
                button.find('.link').attr('href', buttonItem.link);
                button.find('.title').html(buttonItem.title);
                button.removeClass('hidden');
            } else {
                button.remove();
            }
        });
    }, */

    renderChart: function (response) {
        var chartsData = response.data.charts.data.filter(function(item){ return item.chart.active;});
        if (chartsData.length > 0) {
            let sliderConfig = {
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                dots: true,
                appendDots: $('.ticker-slideshow-parent').find('.append-slide-show-dots')
            }
            if (!$('.ticker-slide-show').hasClass('slick-initialized')) {
                chartsData.forEach((chartItem, index) => {
                    let slideTemplate = $('.ticker-slide-show .ticker-slide.template').clone();
                    let expandibleItemTemplate = $('.expandible-chart .listicle.template').clone();
                    slideTemplate.addClass('bg-primary');
                    chartItem.chart.data.forEach((dataItem, idx) => {
                        let itemTemplate = slideTemplate.find('.item.template').clone();
                        itemTemplate.removeClass('template');
                        itemTemplate.attr('id', 'item-' + idx);
                        slideTemplate.find('.items-list').append(itemTemplate[0]);

                        let listicleItemTemplate = expandibleItemTemplate.find('.item.template').clone();
                        listicleItemTemplate.removeClass('template');
                        listicleItemTemplate.attr('id', 'litem-' + idx);
                        expandibleItemTemplate.find('.items-list').append(listicleItemTemplate[0]);
                    });
                    slideTemplate.removeClass('template');
                    slideTemplate.attr('id', 'chart-item-' + index);
                    $('.ticker-slide-show').append('<div>' + $('<div></div>').html(slideTemplate[0]).html() + '</div>');

                    expandibleItemTemplate.removeClass('template');
                    expandibleItemTemplate.attr('id', 'lchart-item-' + index);
                    $('.listicle-holder').append(expandibleItemTemplate[0]);
                });
                $('.ticker-slide-show').find('.template').remove();
                $('.listicle-holder').find('.template').remove();
            }
            chartsData.forEach((chartItem, index) => {
                let chartData = chartItem.chart;
                let slide = $('#chart-item-' + index);
                slide.find('.title').text(chartData.title);

                let listicle = $('#lchart-item-' + index);
                listicle.find('.title').text(chartData.title);

                let ids = ['item', 'litem']

                chartData.data.forEach((dataItem, idx) => {
                    ids.forEach((id) => {
                        let item;
                        if (id == 'item') {
                            item = slide.find('#' + id + '-' + idx);
                        } else {
                            item = listicle.find('#' + id + '-' + idx);
                        }
                        item.find('.left').text(dataItem.left);
                        item.find('.right').text(dataItem.right);
                        item.find('.label').text(dataItem.label);
                        item.find('img').attr('src', dataItem.image);
                    });
                });
            });
            $('.charts-title').text(response.data.charts.title);
            if (!$('.ticker-slide-show').hasClass('slick-initialized')) {
                $('.ticker-slide-show').slick(sliderConfig);
            }
            $('.ticker-chart').removeClass('hidden');
        } else {
            $('.ticker-chart').addClass('hidden');
        }
    },

    /* renderNews: function (response) {
        if (response.data.news.active) {
            this.tickerNews.reset();
            for (var index = 0; index < response.data.news.data.length; index++) {
                this.tickerNews.push(response.data.news.data[index]);
            }
            this.tickerNews.start();
        } else {
            this.tickerNews.stop();
        }
    }, */

    regulate: function () {
        this.tInterval = setInterval(() => this.controller(), 60000);
    },

    position: function () {
        var elem = $('#ticker-ntouch');
        setTimeout(() => {
            if ($('.content-wrapper').children().find('aside').first().length > 0) {
                var asideColumn = $('.content-wrapper').children().find('aside').first();
                var props = asideColumn[0].getBoundingClientRect();
                elem.css({ left: (props.left + (parseInt(asideColumn.css('paddingLeft')))), width: asideColumn.width() });
            }
            this.show();
        }, 1000);
    },

    show: function () {
        $('.ticker-module.' + this.tVersion).removeClass('hidden');
    },

    expandibleChart: function () {
        $(document).on('click', '.ticker-slideshow-parent, .cancel-expandible-chart', function () {
            $('.ticker-slideshow-parent').toggleClass('hidden');
            $('.expandible-chart').toggleClass('hidden');
        })
    }
};

(function () {
    var ticker = Object.create(tickerModule);
    ticker.init();
    ticker.regulate();
})();
